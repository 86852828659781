import React from 'react';
import { Panel, Div, Button, Gallery } from '@vkontakte/vkui';
import app from '../../func/app';
import config from '../../config';
import { getAddScope } from '../../func/vk';

import OneScreen from '../../images/OneScreen.png';
import TwoScreen from '../../images/TwoScreen.png';
import TreeScreen from '../../images/TreeScreen.png';

import './style.css';

class Welcome extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			slideIndex: 0
		};
	}

	renderButton = (slideIndex) => {
		switch (slideIndex) {
			case 0:
				return (
					<Button 
						onClick={() => this.setState({slideIndex: slideIndex === 2 ? 2 : slideIndex + 1 })}
					>
						Далее
					</Button>
				)
			case 1:
				return (
					<Button 
						onClick={() => this.setState({slideIndex: slideIndex === 2 ? 2 : slideIndex + 1 })}
					>
						Хорошо
					</Button>
				)
			case 2:
				return (
					<Button 
						onClick={this.startPril}
					>
						Понимаю
					</Button>
				)
			default:
				//
		}
	}

	startPril = () => {
		//запросить права.
		getAddScope(["wall"], (response) => {
			config.set({access_token: response.access_token}, () => {
				app.setState({
					userScope: response.scope.split(','),
					goStartPanel: false,
				}, () => {
					localStorage.setItem('welcome', 'true')
				})
			})
		})
	}

	render() {
		let { slideIndex } = this.state
		return (
			<Panel theme="white" id={this.props.id}>

					<Gallery
						slideWidth="100%"
						align="center"
						className="WelcomeGallery"
						slideIndex={slideIndex}
						onChange={slideIndex => this.setState({slideIndex})}
						autoplay={0}
						bullets={false}
					>
						<div className="WelcomeOneBlock">
							<div className="WelcomeTextBlock">
								<div className="WelcomeTitle">
									Находите комментарии на любую тему в один клик 
									<div className="WelcomeDescription pt-5">
										Попробуйте найти упоминания Вашей компании или Вашей фамилии ;)
									</div>
								</div>
							</div>
							<div className="WelcomeImg">
								<img alt="" src={OneScreen}/>
							</div>
						</div>

						<div className="WelcomeOneBlock">
							<div className="WelcomeTextBlock">
								<div className="WelcomeTitle">
									Сохраняйте комментарии в избранное, чтобы потом к ним вернуться
								</div>
							</div>
							<div className="WelcomeImg">
								<img alt="" src={TreeScreen}/>
							</div>
						</div>

						<div className="WelcomeOneBlock">
							<div className="WelcomeTextBlock">
								<div className="WelcomeTitle">
									Для работы приложения требуется доступ к постам 
									<div className="WelcomeDescription">
										Без него к сожалению ничего не получится ;)
									</div>
								</div>
							</div>
							<div className="WelcomeImg">
								<img alt="" src={TwoScreen}/>
							</div>
						</div>

					</Gallery>
					<Div className="flex_center">
						{ 
							this.renderButton(slideIndex) 
						}
					</Div>

			</Panel>
		)
	}
}

export default Welcome;