let config = {
	app_id: null, //ид приложения
	admin_app: [152873202, 212705339, 33873307], //админы
	group_id: 39599132,//группа для принятия платежей
	isMemberGid: 39599132,//группа для проверки подписки
	params: null, //параметры запуска
	hash: null, //Хеш приложения при запуске
	userData: null, //Данные о пользователе vk api
	wsUrl: (process.env.NODE_ENV === 'development') ? 'wss://echo.websocket.org' : 'wss://echo.websocket.org', //url wss сервера
	backUrl: (process.env.NODE_ENV === 'development') ? 'https://search.kupiapp2.ru/api/index.php' : 'https://search.kupiapp2.ru/api/index.php', //url бекенда
	api_version: '5.108', //версия апи
	access_token: 'db029decdb029decdb029deceddb6e1503ddb02db029dec8666aac97f212d9963870a35', //ключ доступа апи
	desktopHeader: true, //кастомный хедер для десктопа
	groupChange: false, //другой сервис в группе. true если нужно загружать другие панели для сервиса запущенного в группе
	startPanel: true, //показывать стартовую панель. Если true будет 1 раз загружать стартовую панель, если сервис загружен не в группе.
	userFields: ['photo_50', 'online', 'verified', 'sex'], //данные которые нужно запрашивать у пользователей
	groupFields: ['photo_50', 'verified', 'status'], //данные которые нужно запрашивать у пользователей
	mobileConsole: true, //подключить в режиме разработки мобильную консоль для отладки iOS
	mibileConsoleCDN: 'cdn.jsdelivr.net/npm/eruda', //CDN скрипта с мобиьной консолью
	blackWord: ['цена','стоимость','сколько стоит', 'стоит сколько', 'платить куда', 'куда платить'], //запрещённые к поиску слова
	localStorageСounter: true, //включает счётчик входов в приложение на основе localStorage
	prodError: true, // включить/выключить перехват ошибок на проде
	errorEnv: 'production', //при включеннном prodError работает либо в development либо в production	
	set: function(data, callback = () => {}) { //смена конфига
		for (let key in data) {
			this[key] = data[key]
		}
		callback(true)
	}
}

export default config;