import React from 'react';
import { View, ScreenSpinner, ConfigProvider, Epic, Tabbar, TabbarItem } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

//хелперы
import app from './func/app';
import config from './config';
import navigation from './func/navigation';
import { go } from './func/init';
import { devLog } from './func/utils';

//стартовые панели
import HomeSearch from './panels/HomeSearch';
import HomeFavorite from './panels/HomeFavorite';

//Welcome Panel
import Welcome from './panels/Welcome/Welcome';
//Error Panel
import OnError from './panels/OnError/OnError';

import Icon28Search from '@vkontakte/icons/dist/28/search';
import Icon28Favorite from '@vkontakte/icons/dist/28/favorite';

class App extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			scheme: "client_light", //тема оформления client_light, client_dark, bright_light, space_gray
			header: false, //показывает или скрывает хедер для отображения при кастомном хедере на десктопе
			userScope: [], //права пользователя
			isStartInGroup: false, //сервис запущен из группы
			goStartPanel: false, //показывать стартовый экран
			error: { //ошибка js
				show: false,
				type: null,
				message: null,
				error: null
			},
			isMember: false, //подписан ли на группу

			//навигация
			navPanels: {
				activeStory: "search", //активный эпик вью
				activePanelSearch: "Home", //активная панель
				activePanelFavorite: "Home", //активная панель
			},

			isLoad: false, //загрузка приложения
			popout: null, //всплывающие окна
			apiUsersData: [], //информация о пользователях из апи
			//HomeSearch
			prefix: "type:reply",
			text: "",
			data: {
				groups: [],
				items: [],
				next_from: null,
				profiles: [],
				count: null,
				total_count: null
			},
			favorites: {
				groups: [],
				items: [],
				profiles: [],
				count: null,
			},
			favPagination: {
				count: 30,
				offset: 0,
			}
		};
	}

	componentDidMount() {
		app.setState = (state, callback) => {
			this.setState(state, callback)
		}
		app.state = this.state
		go()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.data !== this.state) {
			app.state = {
				...this.state
			}
		}
	}
	
	onStoryChange = (e) => {
		//this.setState({ activeStory: e.currentTarget.dataset.story })
		//navigation.go({activeStory: e.currentTarget.dataset.story})
		navigation.setStory({ activeStory: e.currentTarget.dataset.story })
	}

	render() {
		devLog(this.state)
		let { isLoad, popout, navPanels, header, scheme, isStartInGroup, goStartPanel, error, isMember } = this.state
		let { prefix, text, data, favorites, favPagination } = this.state
		let { activeStory, activePanelSearch, activePanelFavorite } = navPanels
		let { groupChange, prodError } = config
		if (error.show & prodError) {
			return (
				<ConfigProvider scheme={scheme} webviewType="vkapps">
					<OnError error={error} id="OnError"/>
				</ConfigProvider>
			)
		}
		if (isLoad) { //сервис уже получил все даннные и загружен
			if (goStartPanel) {
				return (
					<ConfigProvider scheme={scheme} webviewType="vkapps">
						<View header={false} id="search" popout={popout} activePanel={activePanelSearch}>
							<Welcome id="Home"/>
						</View>
					</ConfigProvider>
				)
			} else {
				if (isStartInGroup && groupChange) {
					return (
						<ConfigProvider scheme={scheme} webviewType="vkapps">
							<View header={header} id="search" popout={popout} activePanel={activePanelSearch}>
								{
									/*
										компоненты для загрузки из группы, если это включено в config.js
									*/
								}
							</View>
						</ConfigProvider>
					)
				} else { //сервис запущен не из группы
					return (
						<ConfigProvider scheme={scheme} webviewType="vkapps">
							<Epic activeStory={activeStory} tabbar={
								<Tabbar>
									<TabbarItem
										onClick={this.onStoryChange}
										selected={activeStory === 'search'}
										data-story="search"
										text="Поиск"
									>
										<Icon28Search/>
									</TabbarItem>

									<TabbarItem
										onClick={this.onStoryChange}
										selected={activeStory === 'favorite'}
										data-story="favorite"
										text="Избранное"
									>
										<Icon28Favorite/>
									</TabbarItem>
								</Tabbar>
							}>
								<View header={header} id="search" popout={popout} activePanel={activePanelSearch}>
									<HomeSearch 
										id="Home"
										prefix={prefix}
										text={text}
										groups={data.groups}
										items={data.items}
										next_from={data.next_from}
										profiles={data.profiles}
										count={data.count}
										total_count={data.total_count}
										isMember={isMember}
										favGroups={favorites.groups}
									/>
								</View>

								<View header={header} id="favorite" popout={popout} activePanel={activePanelFavorite}>
									<HomeFavorite 
										id="Home"
										groups={favorites.groups}
										profiles={favorites.profiles}
										items={favorites.items}
										count={favorites.count}
										navCount={favPagination.count}
										navOffset={favPagination.offset}
									/>
								</View>
							</Epic>
						</ConfigProvider>
					);				
				}				
			}
		} else { // сервис ещё не получил все данные
			return <ScreenSpinner/>
		}
	}
}

export default App;