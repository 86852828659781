import React from 'react';
import { Group, FormLayout, FormLayoutGroup, Input, Div, Button } from '@vkontakte/vkui';
import './style.css';

class SearchBlock extends React.Component {


	componentDidMount() {
		document.onkeyup = (e) => {
			e = e || window.event;
			if (e.keyCode === 13) {
				let { search, text } = this.props
				if (text.length > 0) {
					search()
				}
			}
			// Отменяем действие браузера
			return false;
		}
	}

	render() {
		let { onChange, text, placeholder, search } = this.props
		return (
			<Group>
				<FormLayout>
					<FormLayoutGroup top="Введите строку для поиска">
						<Input autoComplete="off" onChange={onChange} value={text} name="text" type="text" placeholder={placeholder}/>
					</FormLayoutGroup>
				</FormLayout>
				<Div className="pt-0 flex_end">
					<Button disabled={!(text.length > 0)} onClick={search}>Найти</Button>
				</Div>
			</Group>
		);
	}
}

export default SearchBlock;