/* Галерея выводит фотографии плиткой и осуществляет навигацию по ним  */
import React from 'react';
import { PopoutWrapper } from '@vkontakte/vkui';
import { showImagesBox } from '../../func/vk';
import config from '../../config';
import app from '../../func/app';

//иконки
import Icon24Dismiss from '@vkontakte/icons/dist/24/dismiss';
import { ReactComponent as ChevronForward } from './svg/ChevronForward.svg';
import { ReactComponent as ChevronBack } from './svg/ChevronBack.svg';

//стили
import './style.css';

// сортировка массива объектов размера фото по возрастанию item.sizes.sort(compare)
function compare(a,b) { 
	if (a.width < b.width)
		return -1;
	if (a.width > b.width)
		return 1;
	return 0;
}

class PhotoGalery extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			startNum: 0,
			target: null
		};
	}

	getMyGaleryImages = (photos) => {
		if (!photos) { return [] }
		
		let new_obj = photos.map((item) => {
			let sorts_mass = item.sizes.sort(compare);
			let min_sizes = sorts_mass[3].url;
			let min_sizes_width = sorts_mass[3].width;
			let min_sizes_height = sorts_mass[3].height;
			let max_sizes = sorts_mass[item.sizes.length - 1].url;
			return {
				src: max_sizes,
				thumbnail: min_sizes,
				thumbnailWidth: min_sizes_width,
				thumbnailHeight: min_sizes_height,
			}
		})

		return new_obj;
	}

	renderThumbnail = (images) => {
		const { params } = config
		return images.map((img, i) => {
			return (
				<div key={i} className="ThumbnailBox">
					<img 
						className={(params.vk_platform === "desktop_web") ? "ThumbnailBoxImg" : "ThumbnailBoxImgMobile"}
						onClick={(e) => {this.openImage(e, images)}} 
						alt="" 
						data-src={i}
						src={img.thumbnail}
					/>
				</div>
			)
		})
	}

	goForward = (images) => {
		this.setState((state, props) => ({
			startNum: state.startNum + 1,
			target: images[state.startNum + 1].src
		}), () => {
			app.setState({
				popout: this.myGaleryPopout(images)
			})
		})
	}

	goBack = (images) => {
		this.setState((state, props) => ({
			startNum: state.startNum - 1,
			target: images[state.startNum - 1].src
		}), () => {
			app.setState({
				popout: this.myGaleryPopout(images)
			})
		})
	}

	myGaleryPopout = (images) => {
		const { startNum, target } = this.state
		return (
			<PopoutWrapper  v="center" h="center">
				{
					(startNum === 0) ?
						null
					: 
						<div className="showBoxIcon28ChevronBack">
							<ChevronBack onClick={() => {this.goBack(images)}} className="navSvg"/>
						</div>
				}
				{
					(startNum >= images.length - 1) ?
						null
					:
						<div className="showBoxIcon28ChevronForward">
							<ChevronForward onClick={() => {this.goForward(images)}} className="navSvg"/>
						</div>
				}
				<div className="showBoxImg">
					<div className="showBoxIcon24Dismiss">
						<Icon24Dismiss fill="var(--modal_card_header_close)" onClick={() => {app.setState({popout: null})}}/>
					</div>

					<img  alt="" src={target}/>
				</div>
			</PopoutWrapper>
		)
	}

	openImage = (e, images) => {
		const { params } = config
		const { src } = e.currentTarget.dataset

		switch (params.vk_platform) {
			case 'desktop_web':
				this.setState({
					startNum: +src,
					target: images[src].src
				}, () => {
					app.setState({
						popout: this.myGaleryPopout(images)
					})
				})
			break;
			case 'mobile_web':
				this.setState({
					startNum: +src,
					target: images[src].src
				}, () => {
					app.setState({
						popout: this.myGaleryPopout(images)
					})
				})
			break;
			default:
				const urls = images.map((item) => { return item.src })
				showImagesBox(urls, +src, (error) => {
					console.log(error)
				})
		}
	}

	render() {
		const { photos } = this.props
		const images = this.getMyGaleryImages(photos)
		const { params } = config
		return (
			<div>
				<div className={(params.vk_platform === "desktop_web") ? "AllThumbnails" : "AllThumbnailsMobile"}>
					{
						this.renderThumbnail(images)
					}
				</div>
			</div>
		);
	}
}

export default PhotoGalery;